import React from 'react'
import {
    AiOutlineGithub,
    AiOutlineTwitter,
    AiOutlineLinkedin,
    AiOutlineYoutube,
  } from "react-icons/ai"

const Footer = () => {
  return (
    <footer className='mx-auto max-w-3xl px-4 sm:px-6 md:max-5-xl'>
            <hr className='w-full h-0.5 mx-auto mt-8 bg-neutral-200 border-0'/>
            <div className='mx-auto p-4 flex flex-col text-center text-neutral-500 dark:text-neutral-100 md:flex-row md:justify-between'>
                <div>© 2023 Nathanael Ama</div>
                <div className='flex flex-row items-center justify-center' >
                    <a href='https://github.com/NathanaelAma' rel='noreferrer' target="_blank">
                        <AiOutlineGithub className='hover:-translate-y-1 transition-transform cursor-pointer text-neutral-500 dark:text-neutral-100' size={30}/>
                    </a>
                    <a href='https://twitter.com/ThisIsNate_' rel='noreferrer' target="_blank">
                        <AiOutlineTwitter className='hover:-translate-y-1 transition-transform cursor-pointer text-neutral-500 dark:text-neutral-100' size={30}/>
                    </a>
                    <a href='https://www.linkedin.com/in/nathanaelama/' rel='noreferrer' target="_blank">
                        <AiOutlineLinkedin className='hover:-translate-y-1 transition-transform cursor-pointer text-neutral-500 dark:text-neutral-100' size={30}/>
                    </a>
                </div>
            </div>
    </footer>
  )
}

export default Footer